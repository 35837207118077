import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import progressLogo from '../../assets/logos/original/progress-logo.svg';
import progressLogoWhite from '../../assets/logos/white/progress-logo.svg';
import progressLogoBlack from '../../assets/logos/black/progress-logo.svg';
import progressLogoPrimary from '../../assets/logos/primary/progress-logo.svg';
import progressLogoSecondary from '../../assets/logos/secondary/progress-logo.svg';
import equalizerLogo from '../../assets/logos/original/equalizer-logo.svg';
import equalizerLogoWhite from '../../assets/logos/white/equalizer-logo.svg';
import equalizerLogoBlack from '../../assets/logos/black/equalizer-logo.svg';
import equalizerLogoPrimary from '../../assets/logos/primary/equalizer-logo.svg';
import equalizerLogoSecondary from '../../assets/logos/secondary/equalizer-logo.svg';
import fastwaytrailerLogo from '../../assets/logos/original/fastway-logo.svg';
import fastwaytrailerLogoWhite from '../../assets/logos/white/fastway-logo.svg';
import fastwaytrailerLogoBlack from '../../assets/logos/black/fastway-logo.svg';
import fastwaytrailerLogoPrimary from '../../assets/logos/primary/fastway-logo.svg';
import fastwaytrailerLogoSecondary from '../../assets/logos/secondary/fastway-logo.svg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "logos"
    }}>{`Logos`}</h1>
    <p>{`There are a few variations of logos that can be used for each brand.  Most logos that will be used will be a single flat color used as an SVG so they're able to be changed dynamically.`}</p>
    <p>{`All logos should be in SVG format to allow for clean crisp viewing online and Retina displays. You will need to import these on an individual basis.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import progressLogo from '../../assets/logos/original/progress-logo.svg';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img src={progressLogo} alt="Original Progress Mfg. Logo" />
`}</code></pre>
    <h2 {...{
      "id": "progress-manufacturing"
    }}>{`Progress Manufacturing`}</h2>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={progressLogo} alt="Original Progress Mfg. Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Original Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={progressLogoWhite} alt="White Progress Mfg. Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">White Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={progressLogoBlack} alt="Black Progress Mfg. Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Black Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={progressLogoPrimary} alt="Primary Color Progress Mfg. Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Primary Color Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={progressLogoSecondary} alt="Secondary Color Progress Mfg. Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Secondary Color Logo</Typography>
    </Box>
    </Box>
    <Divider variant="middle" mdxType="Divider" />
    <h2 {...{
      "id": "equal-i-zer-hitch"
    }}>{`Equal-i-zer Hitch`}</h2>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={equalizerLogo} alt="Original Equal-i-zer Hitch Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Original Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={equalizerLogoWhite} alt="White Equal-i-zer Hitch Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">White Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={equalizerLogoBlack} alt="Black Equal-i-zer Hitch Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Black Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={equalizerLogoPrimary} alt="Primary Color Equal-i-zer Hitch Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Primary Color Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={equalizerLogoSecondary} alt="Secondary Color Equal-i-zer Hitch Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Secondary Color Logo</Typography>
    </Box>
    </Box>
    <Divider variant="middle" mdxType="Divider" />
    <h2 {...{
      "id": "fastway-trailer-products"
    }}>{`Fastway Trailer Products`}</h2>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={fastwaytrailerLogo} alt="Original Fastway Trailer Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Original Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={fastwaytrailerLogoWhite} alt="White Fastway Trailer Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">White Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={fastwaytrailerLogoBlack} alt="Black Fastway Trailer Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Black Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={fastwaytrailerLogoPrimary} alt="Primary Color Fastway Trailer Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Primary Color Logo</Typography>
    </Box>
    <Box style={{
        marginRight: "25px",
        marginBottom: "25px",
        textAlign: "center"
      }} mdxType="Box">
        <img src={fastwaytrailerLogoSecondary} alt="Secondary Color Fastway Trailer Logo" style={{
          height: "80px"
        }} />
        <Typography variant="subtitle2" mdxType="Typography">Secondary Color Logo</Typography>
    </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      